import Button from "@/components/button";
import Input from "@/components/input";

export default {
    name: "neo-create-case",
    components: {
        "neo-button": Button,
        "neo-input": Input,
    },
    props: [],
    data() {
        return {
            allPersons: [],
            allCompanies: [],
            newPerson: {
                expanded: false,
                first_name: "",
                last_name: "",
                email: "",
            },
            newCompany: {
                expanded: false,
                name: "",
                domain: "",
                email: "",
            },
        };
    },
    computed: {},
    mounted() {},
    methods: {
        addPerson() {
            this.allPersons.push(this.newPerson);
            this.newPerson = {
                expanded: false,
                first_name: "",
                last_name: "",
                email: "",
            };
        },
        addCompany() {
            this.allCompanies.push(this.newCompany);
            this.newCompany = {
                expanded: false,
                name: "",
                domain: "",
                email: "",
            };
        },
        handleExpand(data) {
            data.expanded = !data.expanded;
        },
    },
};
